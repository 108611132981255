<template>
    <div class="link-manage-table link-table">
        <div class="link-list">
            <draggable
                v-model="list"
                :disabled="dragStatus"
                animation="300"
                @update="draggupdate"
            >
                <transition-group>
                    <div
                        v-for="item of list"
                        :key="item.id"
                        class="link-item link-item--move"
                    >
                        <div class="cell">
                            <el-input
                                v-if="currentEditId === item.id"
                                v-model="postData.link_name"
                                placeholder="请输入标题"
                                clearable
                            ></el-input>
                            <span v-else>{{ item.link_name }}</span>
                        </div>
                        <div class="cell">
                            <el-input
                                v-if="currentEditId === item.id"
                                v-model="postData.link"
                                placeholder="请输入链接"
                                clearable
                            ></el-input>
                            <span v-else>
                                <!-- {{ item.link }} -->
                                <el-link
                                    :href="item.link"
                                    :target="linkTarget(item.link)"
                                    >{{ item.link }}</el-link
                                >
                            </span>
                        </div>
                        <div class="cell">
                            <el-select
                                v-model="postData.group_id"
                                placeholder="分组选择"
                                clearable
                                v-if="currentEditId === item.id"
                            >
                                <el-option
                                    v-for="itemGroup of group"
                                    :key="itemGroup.id"
                                    :label="itemGroup.group_name"
                                    :value="itemGroup.id"
                                ></el-option>
                            </el-select>
                            <span v-else>{{ item.group_name }}</span>
                        </div>
                        <div class="cell">
                            <el-button
                                type="primary"
                                v-if="currentEditId !== item.id"
                                @click="editData(item)"
                                :disabled="status1"
                                >修改</el-button
                            >
                            <el-button
                                type="primary"
                                v-if="currentEditId === item.id"
                                @click="updateData(item)"
                                >保存</el-button
                            >
                            <el-button
                                type="info"
                                v-if="currentEditId === item.id"
                                @click="cancelEdit"
                                >取消</el-button
                            >
                            <el-button
                                type="danger"
                                v-if="currentEditId !== item.id"
                                @click="deleteData(item.id)"
                                >删除</el-button
                            >
                        </div>
                    </div>
                </transition-group>
            </draggable>
            <div
                class="link-item"
                :class="{ 'link-item--blue': isbgBlueActive }"
            >
                <div class="cell">
                    <el-input
                        v-if="addStatus"
                        v-model="postData.link_name"
                        placeholder="请输入标题"
                        clearable
                    ></el-input>
                    <span v-else></span>
                </div>
                <div class="cell">
                    <el-input
                        v-if="addStatus"
                        v-model="postData.link"
                        placeholder="请输入链接"
                        clearable
                    ></el-input>
                    <span v-else></span>
                </div>
                <div class="cell">
                    <el-select
                        v-model="postData.group_id"
                        placeholder="分组选择"
                        v-if="addStatus"
                    >
                        <el-option
                            v-for="itemGroup of group"
                            :key="itemGroup.id"
                            :label="itemGroup.group_name"
                            :value="itemGroup.id"
                        ></el-option>
                    </el-select>
                    <span v-else></span>
                </div>
                <div class="cell">
                    <el-button
                        type="primary"
                        v-show="!addStatus"
                        @click="addData"
                        :disabled="status1"
                        >新增</el-button
                    >
                    <el-button
                        type="primary"
                        v-if="addStatus"
                        @click="updateData"
                        >保存</el-button
                    >
                    <el-button type="info" v-if="addStatus" @click="cancelEdit"
                        >取消</el-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    props: {
        linkList: {
            type: Array,
        },
        group: {
            type: Array,
        },
    },
    components: { draggable },
    data() {
        return {
            // 用于判断当前编辑的链接
            currentEditId: "",
            // 用于最后一栏链接新增的状态管理
            addStatus: false,
            status1: false,
            list: [],
            // group: [],
            postData: {
                id: "",
                link_name: "",
                link: "",
                group_id: "",
            },
        };
    },
    computed: {
        // 控制拖拽的状态
        dragStatus() {
            return this.currentEditId == "" ? false : "disabled";
        },
        isbgBlueActive() {
            return this.list.length % 2 === 0 ? true : false;
        },
    },
    watch: {
        linkList: function (newData) {
            this.list = newData;
        },
    },
    methods: {
        linkTarget(link) {
            return link.startsWith("#") ? "_self" : "_target";
        },
        draggupdate() {
            const listOrder = [],
                listLength = this.list.length;
            this.list.forEach((item, index) => {
                item.order = listLength - index - 1;
                listOrder.push({ id: item.id, order: item.order });
            });
            this.$ajax("account/update-custom-link-order", listOrder).done(
                () => {
                    this.$emit("getLinkData");
                }
            );
        },

        // 链接编辑
        editData(item) {
            // 停止新增操作
            this.addStatus = false;
            this.status1 = true;
            console.log(this.status1);

            this.currentEditId = item.id;
            // 获取链接数据 输出到输入框中
            this.postData.id = item.id;
            this.postData.link_name = item.link_name;
            this.postData.link = item.link;
            this.postData.group_id = item.group_id;
        },
        // 链接新增
        addData() {
            // 停止修改操作
            this.currentEditId = "";
            this.status1 = true;

            this.addStatus = true;
            this.emtpyData();
        },
        updateData(item) {
            const urlRegex = /^(http|https):\/\/([\w.-]+)(?::(\d+))?(\/.*)?$/i;
            const vueRouteRegex = /^#\/(?:[-\w]+\/)*(?:[-\w]+)$/i;

            // 数据校验
            if (!this.postData.link_name) {
                this.$message.error("链接名称不能为空");
                return false;
            }
            if (!this.postData.link) {
                this.$message.error("链接不能为空");
                return false;
            }

            if (
                !urlRegex.test(this.postData.link) &&
                !vueRouteRegex.test(this.postData.link)
            ) {
                this.$message.error("链接格式不符合");
                return false;
            }

            if (
                item.link_name === this.postData.link_name &&
                item.link === this.postData.link &&
                item.group_id === this.postData.group_id
            ) {
                this.cancelEdit();
                return;
            }

            // 链接校验
            if (this.postData.id) {
                this.$ajax("/account/update-custom-link", {
                    id: this.postData.id,
                    link: this.postData.link,
                    link_name: this.postData.link_name,
                    group_id: this.postData.group_id,
                })
                    .done(() => {
                        this.$emit("getLinkData", "链接修改成功");
                    })
                    .always(() => {
                        this.cancelEdit();
                    });
            } else {
                this.$ajax("/account/add-custom-link", {
                    link: this.postData.link,
                    link_name: this.postData.link_name,
                    group_id: this.postData.group_id,
                })
                    .done(() => {
                        this.$emit("getLinkData", "链接新增成功");
                    })
                    .always(() => {
                        this.cancelEdit();
                    });
            }
        },
        deleteData(id) {
            let that = this;
            this.$confirm("此操作将永久删除该链接, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    that.$ajax("account/remove-custom-link", {
                        id,
                    }).done(function () {
                        that.$emit("getLinkData", "删除成功!");
                    });
                })
                .catch(function () {
                    that.$message.info("已取消删除");
                });
        },
        cancelEdit() {
            this.emtpyData();
            this.changeEditStatus();
        },
        // 清空输入内容
        emtpyData() {
            this.postData.id = "";
            this.postData.link_name = "";
            this.postData.link = "";
            this.postData.group = "";
        },
        // 还原状态
        changeEditStatus() {
            this.currentEditId = "";
            this.addStatus = false;
            this.status1 = false;
        },
    },
    mounted() {
        this.list = this.linkList;
        window.me = this;
    },
};
</script>

<style lang="less">
@import "../../../less/abstracts/variables.less";
</style>
