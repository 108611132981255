<template>
    <div class="link-group-table link-table">
        <div class="link-list">
            <div v-for="item of group" :key="item.id" class="link-item">
                <div class="cell">
                    <el-input
                        v-if="currentEditId === item.id"
                        v-model="postData.group_name"
                        placeholder="请输入分组标题"
                        clearable
                    ></el-input>
                    <span v-else>{{ item.group_name }}</span>
                </div>
                <div class="cell">
                    <el-button
                        type="primary"
                        v-if="currentEditId !== item.id"
                        @click="editData(item)"
                        >修改</el-button
                    >
                    <el-button
                        type="primary"
                        v-if="currentEditId === item.id"
                        @click="updateData"
                        >保存</el-button
                    >
                    <el-button
                        type="info"
                        v-if="currentEditId === item.id"
                        @click="cancelEdit"
                        >取消</el-button
                    >
                    <el-button
                        type="danger"
                        v-if="currentEditId !== item.id"
                        @click="deleteData(item.id)"
                        >删除</el-button
                    >
                </div>
            </div>

            <div
                class="link-item"
                :class="{ 'link-item--blue': isbgBlueActive }"
            >
                <div class="cell">
                    <el-input
                        v-if="addStatus"
                        v-model="postData.group_name"
                        placeholder="请输入新增分组标题"
                        clearable
                    ></el-input>
                    <span v-else></span>
                </div>
                <div class="cell">
                    <el-button type="primary" v-if="!addStatus" @click="addData"
                        >新增</el-button
                    >
                    <el-button
                        type="primary"
                        v-if="addStatus"
                        @click="updateData"
                        >保存</el-button
                    >
                    <el-button type="info" v-if="addStatus" @click="cancelEdit"
                        >取消</el-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        group: {
            type: Array,
        },
    },
    data() {
        return {
            currentEditId: "",
            addStatus: false,
            // group: [],
            postData: {
                id: "",
                group_name: "",
            },
        };
    },
    computed: {
        isbgBlueActive() {
            return this.group.length % 2 === 0 ? true : false;
            // return this.group.length % 2 === 0 && this.group.length !== 0
            //     ? true
            //     : false;
        },
    },
    methods: {
        editData(item) {
            // 停止新增操作
            this.addStatus = false;

            // 获取链接数据 输出到输入框中
            this.currentEditId = item.id;
            this.postData.id = item.id;
            this.postData.group_name = item.group_name;
        },
        addData() {
            // 停止修改操作
            this.currentEditId = "";

            this.addStatus = true;
            this.emtpyData();
        },

        updateData() {
            // 数据校验
            if (!this.postData.group_name) {
                this.$message.error("分组名称不能为空");
                return false;
            }

            const isExist = this.group.find(
                (item) => item.group_name === this.postData.group_name
            );
            if (isExist) {
                this.$message.error("分组名称已存在，请更换其他名称");
                return false;
            }

            if (this.postData.id) {
                this.$ajax("/account/update-custom-link-group", {
                    id: this.postData.id,
                    group_name: this.postData.group_name,
                })
                    .done(() => {
                        this.$emit("getData");
                        this.$message.success("分组修改成功！");
                    })
                    .always(() => {
                        this.cancelEdit();
                    });
            } else {
                this.$ajax("/account/add-custom-link-group", {
                    group_name: this.postData.group_name,
                })
                    .done(() => {
                        this.$emit("getData");
                        this.$message.success("分组添加成功！");
                    })
                    .always(() => {
                        this.cancelEdit();
                    });
            }
        },

        deleteData(id) {
            let that = this;
            this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    that.$ajax("account/remove-custom-link-group", {
                        id,
                    }).done(function () {
                        that.$emit("getData");
                        that.$message.success("删除成功!");
                    });
                })
                .catch(function () {
                    that.$message.info("已取消删除");
                });
        },

        cancelEdit() {
            this.changeEditStatus();
            this.emtpyData();
        },

        // 清空输入内容
        emtpyData() {
            this.postData.id = "";
            this.postData.group_name = "";
        },

        // 还原状态
        changeEditStatus() {
            this.currentEditId = "";
            this.addStatus = false;
        },
    },
};
</script>

<style lang="less">
@import "../../../less/page/link.less";
</style>
