<template>
    <el-tabs v-model="activeName" type="card" class="link-tab">
        <el-tab-pane label="链接管理" name="first">
            <link-manage
                :linkList="linkList"
                :group="linkGroupList"
                @getLinkData="getList"
                v-if="listReady"
            ></link-manage>
        </el-tab-pane>
        <el-tab-pane label="分组管理" name="second">
            <link-group :group="linkGroupList" @getData="getData"></link-group>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import linkGroup from "@/views/work-link/component/link-group.vue";
import linkManage from "@/views/work-link/component/link-manage.vue";
export default {
    // 链接管理组件、分组管理组件
    components: { linkManage, linkGroup },

    data() {
        return {
            // tab默认选项
            activeName: "first",
            // activeName: "second",
            linkList: [],
            listReady: false,
            linkGroupList: [],
        };
    },

    methods: {
        getList(msg) {
            this.$ajax("/account/get-custom-links")
                .done((res) => {
                    if (res && res.length > 0) {
                        this.linkList = res;
                        if (msg) {
                            this.$message.success(msg);
                        }
                    } else {
                        this.linkList = [];
                    }
                })
                .always(() => {
                    this.listReady = true;
                });

            this.$ajax("/account/get-custom-links", { type: 2 }).done((res) => {
                if (res) {
                    this.$store.commit("updateNormalLinkList", res);
                }
            });
        },
        getGroup() {
            this.$ajax("/account/get-custom-link-groups").done((res) => {
                if (res && res.length > 0) {
                    this.linkGroupList = res;
                } else {
                    this.linkGroupList = [];
                }
            });
            // .always(() => {
            //     this.linkGroupList.unshift({ id: "", group_name: "" });
            // });
        },
        getData() {
            this.getList();
            this.getGroup();
        },
    },

    mounted() {
        this.getData();
    },
};
</script>
